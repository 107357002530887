<template>
  <ion-header>
    <ion-toolbar>
      <ion-button v-if="memSlot1 !== null" slot="start" fill="outline" size="small" color="medium" class="memSlotButton" @click="enterNumber(memSlot1)">
        BAL {{number_format(memSlot1) }}
      </ion-button>
      <ion-button v-if="memSlot2 !== null" slot="start" fill="outline" size="small" color="medium" class="memSlotButton" @click="enterNumber(memSlot2)">
        BAL {{number_format(memSlot2) }}
      </ion-button>
      <ion-buttons slot="end">
        <ion-button @click="Cancel">Cancel</ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <!-- debugging
    <ion-item lines="full">
      <div>firstVal: {{ firstVal }}</div>
    </ion-item>
    <ion-item lines="full">
      <div>newCursor: {{ newCursor ? 'true' : 'false' }}</div>
    </ion-item>
    <ion-item lines="full">
      <div>userClickDigitYet: {{ userClickDigitYet ? 'true' : 'false' }}</div>
    </ion-item>
    -->
    <ion-item color="dark" lines="full">
      <template v-if="operator && firstVal !== null && !newCursor">
        <span>{{displayFirstValWithCommas}}</span>
        &nbsp;
        <span class="opSelectedColor">{{operator}}</span>
      </template>
      <ion-label style="text-align: right; font-size: 240%;">{{ displayWithCommas }}</ion-label>
    </ion-item>
    <ion-grid>
      <ion-row>
        <ion-col>
          <template v-if="isC === true">
            <button @click="click('C')" class="btn operator clear">C</button>
          </template>
          <template v-else>
            <button @click="click('AC')" class="btn operator clear">AC</button>
          </template>
        </ion-col>
        <ion-col><button @click="click('/')" class="btn operator" :class="{opSelected:operator==='/'}">/</button></ion-col>
        <ion-col><button @click="click('*')" class="btn operator" :class="{opSelected:operator==='*'}">x</button></ion-col>
        <ion-col><button @click="click('-')" class="btn operator" :class="{opSelected:operator==='-'}">-</button></ion-col>
        <ion-col><button @click="click('+')" class="btn operator" :class="{opSelected:operator==='+'}">+</button></ion-col>
        <ion-col><button @click="click('=')" class="btn operator">=</button></ion-col>
      </ion-row>
      <ion-row>
        <ion-col><button @click="click('1')" class="btn digit">1</button></ion-col>
        <ion-col><button @click="click('2')" class="btn digit">2</button></ion-col>
        <ion-col><button @click="click('3')" class="btn digit">3</button></ion-col>
      </ion-row>
      <ion-row>
        <ion-col><button @click="click('4')" class="btn digit">4</button></ion-col>
        <ion-col><button @click="click('5')" class="btn digit">5</button></ion-col>
        <ion-col><button @click="click('6')" class="btn digit">6</button></ion-col>
      </ion-row>
      <ion-row>
        <ion-col><button @click="click('7')" class="btn digit">7</button></ion-col>
        <ion-col><button @click="click('8')" class="btn digit">8</button></ion-col>
        <ion-col><button @click="click('9')" class="btn digit">9</button></ion-col>
      </ion-row>
      <ion-row>
        <ion-col><button @click="click('.')" class="btn digit second">.</button></ion-col>
        <ion-col><button @click="click('0')" class="btn digit">0</button></ion-col>
        <ion-col><button @click="OK" class="btn digit second">OK</button></ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
</template>

<script>
import {
  // Utilities
  modalController,
  // ionic components
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonItem,
  IonLabel,
  IonRow,
  IonToolbar,
} from "@ionic/vue";
import { numberFormatComma } from "../../helper.js";
export default {
  name: "Calculator",
  components: {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonItem,
    IonLabel,
    IonRow,
    IonToolbar,
  },
  data() {
    const val = this.value.toString();
    const len = val.length;
    return {
      userClickDigitYet: false, // ผู้ใช้กดเลขอะไรแล้วรึยัง (ใช้ดักกรณีที่มีเลขมาใน props อยู่แล้วแต่แรก ถ้ากดเลขเราจะให้ขึ้นเป็นเลขใหม่เลย แต่ถ้ากดเครื่องหมาย เราจะให้คำนวณต่อได้ทันที)
      display: len ? val : "0",
      firstVal: null,
      operator: null,
      newCursor: val === "0" || len === 0,
      isC: false,
      elController: null,
      inputting: false,
    };
  },
  props: {
    value: {
      type: String,
      default: "0",
    },
    memSlot1: {
      type: Number,
      default: null,
    },
    memSlot2: {
      type: Number,
      default: null,
    },
  },
  computed: {
    displayWithCommas() {
      return numberFormatComma(this.display);
    },
    displayFirstValWithCommas() {
      return numberFormatComma(this.firstVal);
    },
    digitBtnClasses() {
      return {
        digitButton: true,
      };
    },
  },
  methods: {
    async click(key) {
      if (this.inputting)
        return;
      this.inputting = true;
      switch (key) {
        case "AC":
          this.display = "0";
          this.firstVal = null;
          this.operator = null;
          this.newCursor = true;
          this.userClickDigitYet = false;
          break;
        case "C":
          this.display = "0";
          this.newCursor = true;
          this.userClickDigitYet = false;
          this.isC = false;
          break;
        case "=":
          this.clickEqual();
          break;
        default:
          if (
            key === "." ||
            (key.charCodeAt(0) >= "0".charCodeAt(0) &&
              key.charCodeAt(0) <= "9".charCodeAt(0))
          ) {
            this.addNumber(key);
          } else if (
            key === "/" ||
            key === "*" ||
            key === "-" ||
            key === "+"
          ) {
            this.addOperator(key);
          }
          break;
      }
      this.inputting = false;
    },
    enterNumber(amount) {
      if (this.operator !== null) {
        this.firstVal = parseFloat(this.display);
      }
      this.display = amount.toString();
      this.newCursor = false;
      this.isC = true;
      this.userClickDigitYet = true;
    },
    addNumber(num) {
      if (num === ".") {
        if (this.newCursor === true || !this.userClickDigitYet) {
          this.display = "0.";
          this.newCursor = false;
        } else if (this.display.indexOf(".") === -1) {
          this.display += ".";
        }
      } else if (num === "0") {
        if (this.newCursor === true || !this.userClickDigitYet) {
          this.display = num;
          this.newCursor = false;
        } else if (this.display !== "0") {
          this.display = this.display.toString() + num;
        }
      } else {
        if (this.newCursor === true || !this.userClickDigitYet) {
          this.display = num;
          this.newCursor = false;
        } else if (this.display === "0") {
          this.display = num;
        } else {
          this.display = this.display.toString() + num;
        }
      }
      this.isC = true;
      this.userClickDigitYet = true;
    },
    addOperator(operator) {
      if (this.newCursor === true) { // ช่วงที่เปลี่ยน operator ไปมา โดยไม่เกิดการคำนวณ
        this.operator = operator;
      }
      else {
        if (this.firstVal === null) { // ยังไม่มี เลขชุดแรก
          this.firstVal = parseFloat(this.display); // เอาค่าไปเก็บใน เลขชุดแรก
        } else if (this.operator !== null) {
          const result = this.calculate(this.firstVal, parseFloat(this.display)); // คำนวณ
          this.firstVal = result;
          this.display = result.toString();
        }
        this.operator = operator;
        this.newCursor = true;
      }
    },
    clickEqual() {
      if (this.operator !== null) {
        if (this.firstVal !== null) {
          const result = this.calculate(this.firstVal, parseFloat(this.display));
          this.firstVal = null;
          this.display = result.toString();
        }
        // เวลาคำนวณแล้ว ให้เคลีย operator เดิมออก
        this.operator = null;
        this.newCursor = true;
        this.firstVal = null;
      }
    },
    calculate(a, b) {
      let result = "";
      switch (this.operator) {
        case "/":
          result = a / b;
          break;
        case "*":
          result = a * b;
          break;
        case "-":
          result = a - b;
          break;
        case "+":
          result = a + b;
          break;
      }
      return result;
    },
    OK() {
      if (this.newCursor === false) {
        this.clickEqual();
      }
      modalController.dismiss(this.display);
    },
    Cancel() {
      modalController.dismiss();
    },
    number_format(num) {
      return numberFormatComma(num);
    },
  },
  mounted() {
    this.elController = new AbortController();
    window.addEventListener("keyup", (event) => {
      if (event.key === "Enter") {
        this.OK();
      } else {
        this.click(event.key);
      }
    }, { signal: this.elController.signal });
  },
  unmounted() {
    this.elController.abort();
  },
};
</script>

<style scoped>
ion-col {
  padding: 0;
}
ion-col ion-button {
  margin: 1px;
}
.digitButton {
  height: 60px;
}
.memSlotButton {
  --padding-start: 4px;
  --padding-end: 4px;
  --padding-top: 4px;
  --padding-bottom: 4px;
  --border-style: dashed;
}

.btn {
  width: 100%;
  border: 1px solid white;
}
.btn.digit {
  color: white;
  height: 60px;
  background-color: rgb(56, 128, 255);
}
.btn.digit.second {
  background-color: rgb(82, 96, 255);
}
.btn.operator {
  height: 44px;
  color: black;
  background-color: rgb(255, 196, 9);
}
.btn.operator.clear {
  color: white;
  background-color: rgb(235, 68, 90);
}
.btn[disabled] {
  opacity: 0.5;
}
.btn.opSelected {
  font-weight: bold;
  background-color: rgb(168, 255, 61);
}
.opSelectedColor {
  color: rgb(168, 255, 61);
}
</style>
