<template>
  <ion-page>
    <!-- header -->
    <ion-header>
      <ion-toolbar>
        <template v-if="isMobile">
          <ion-text slot="start" color="medium">
            {{ shortenCurrentSaveName }}
          </ion-text>
        </template>
        <ion-segment v-model="mode">
          <ion-segment-button value="expense" class="expense">
            <ion-label>รายจ่าย</ion-label>
          </ion-segment-button>
          <ion-segment-button value="transfer" class="transfer">
            <ion-label>โอน</ion-label>
          </ion-segment-button>
          <ion-segment-button value="income" class="income">
            <ion-label>รายรับ</ion-label>
          </ion-segment-button>
        </ion-segment>
        <ion-buttons slot="end">
          <ion-button @click="close">
            <ion-icon :ios="icons.closeOutline" :md="icons.closeSharp"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <!-- content -->
    <ion-content :fullscreen="true">
      <!-- asset 1 -->
      <asset-selector
        :label="asset1Label"
        :suggested-accounts="asset1Accounts"
        :all-accounts="currentSaveAssets"
        :value="form.asset1_id"
        @input="setAsset1"
      ></asset-selector>

      <!-- account 2 -->
      <template v-if="mode === 'transfer'">
        <!-- asset 2 -->
        <asset-selector
          label="ไปยังบัญชี"
          :suggested-accounts="asset2Accounts"
          :all-accounts="currentSaveAssets"
          :value="form.asset2_id"
          @input="setAsset2"
        ></asset-selector>
      </template>
      <template v-else>
        <!-- category_id -->
        <asset-selector
          label="ประเภท / หมวดหมู่"
          :suggested-accounts="suggestedCategoryAccounts"
          :all-accounts="currentSaveCategories"
          :value="form.category_id"
          :category-mode="true"
          @input="setCategory"
        ></asset-selector>
      </template>

      <!-- divider -->
      <ion-item-divider class="small">
        <ion-label>&nbsp;</ion-label>
      </ion-item-divider>

      <!-- amount -->
      <ion-item lines="full" @click="openCalculatorModal">
        <ion-icon slot="start" :ios="icons.amount" :md="icons.amount"></ion-icon>
        <ion-label>Amount</ion-label>
        <ion-input class="ion-text-right" :value="displayAmountWithCommas" readonly @ionFocus="onFocusAmount"></ion-input>
      </ion-item>

      <!-- note -->
      <ion-item lines="full">
        <ion-icon slot="start" :ios="icons.noteOutline" :md="icons.noteSharp"></ion-icon>
        <ion-label>Note</ion-label>
        <ion-input ref="note" type="text" v-model="form.note" class="ion-text-right"></ion-input>
      </ion-item>

      <!-- date/time -->
      <ion-item lines="full">
        <ion-icon slot="start" :ios="icons.dateTimeOutline" :md="icons.dateTimeSharp"></ion-icon>
        <ion-label>วันที่/เวลา</ion-label>
        <ion-datetime
          display-format="DD MMM YYYY HH:mm"
          min="1997"
          v-model="form.datetime"
        ></ion-datetime>
      </ion-item>

      <!-- Save button -->
      <ion-button ref="save" expand="block" @click="Save" :disabled="isSaveButtonDisabled">
        <ion-icon :ios="submitButtonIconOutline" :md="submitButtonIconSharp"></ion-icon>
        {{ submitButtonLabel }}
      </ion-button>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  // Utilities
  isPlatform,
  modalController,
  alertController,
  // Ionic Components
  IonButton,
  IonButtons,
  IonContent,
  IonDatetime,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonText,
  IonToolbar,
} from "@ionic/vue";

import {
  addOutline, addSharp,
  calendarOutline, calendarSharp,
  chatbubbleOutline, chatbubbleSharp,
  closeOutline, closeSharp,
  logoBitcoin, saveOutline, saveSharp,
} from "ionicons/icons";
import { mapActions, mapGetters } from "vuex";
import AssetSelector from "./AssetSelector.vue";
import Calculator from "./Calculator.vue";
import { numberFormatComma } from "../../helper.js";

export default {
  name: "ModalTransactionEditor",
  components: {
    // Ionic Components
    IonButton,
    IonButtons,
    IonContent,
    IonDatetime,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonPage,
    IonSegment,
    IonSegmentButton,
    IonText,
    IonToolbar,
    // Our components
    AssetSelector,
  },
  data() {
    let mode = this.initMode;
    let asset1_id = null;
    let asset2_id = null;
    let category_id = null;
    let amount = "";
    let note = "";
    let datetime;

    if (this.transaction) {
      if (this.transaction.transaction_format === "withdraw") {
        mode = "expense";
        asset1_id = this.transaction.credit_id;
        category_id = this.transaction.debit_id;
      } else if (this.transaction.transaction_format === "deposit") {
        mode = "income";
        asset1_id = this.transaction.debit_id;
        category_id = this.transaction.credit_id;
      } else if (this.transaction.transaction_format === "transfer") {
        mode = "transfer";
        asset1_id = this.transaction.credit_id;
        asset2_id = this.transaction.debit_id;
      }
      amount = this.transaction.amount;
      note = this.transaction.transaction_detail;

      if (this.transaction.transaction_date) {
        let datetime_str = `${this.transaction.transaction_date}`;
        if (this.transaction.transaction_time) {
          datetime_str += `T${this.transaction.transaction_time}+07:00`;
        }
        datetime = new Date(Date.parse(datetime_str)).toISOString();
      }
      else {
        datetime = new Date().toISOString();
      }
    } else {
      datetime = new Date().toISOString();
    }

    return {
      mode,
      form: {
        asset1_id,
        asset2_id,
        category_id,
        datetime,
        amount,
        note,
      },
      calculatorOpened: false,
      submitting: false,
    };
  },
  props: {
    initMode: { type: String },
    transaction: { type: Object },
  },
  computed: {
    ...mapGetters({
      currentSaveAssets: "account/currentSaveAssets",
      currentSaveCategories: "account/currentSaveCategories",
      currentSaveName: "save/currentSaveName",
      suggestedAssetsForDeposit: "account/suggestedAssetsForDeposit",
      suggestedAssetsForTransferFrom: "account/suggestedAssetsForTransferFrom",
      suggestedAssetsForTransferTo: "account/suggestedAssetsForTransferTo",
      suggestedAssetsForWithdraw: "account/suggestedAssetsForWithdraw",
      suggestedCategoriesForDeposit: "account/suggestedCategoriesForDeposit",
      suggestedCategoriesForWithdraw: "account/suggestedCategoriesForWithdraw",
      hashedAccounts: "account/hashedAccounts",
    }),
    icons() {
      return {
        closeOutline,
        closeSharp,
        amount: logoBitcoin,
        dateTimeOutline: calendarOutline,
        dateTimeSharp: calendarSharp,
        noteOutline: chatbubbleOutline,
        noteSharp: chatbubbleSharp,
        addOutline: addOutline,
        addSharp: addSharp,
        saveOutline: saveOutline,
        saveSharp: saveSharp,
      };
    },
    isMobile() {
      return isPlatform("mobile") || isPlatform("mobileweb");
    },
    shortenCurrentSaveName() {
      const length = 10;
      return (
        this.currentSaveName.substring(0, length) +
        (this.currentSaveName.length > length ? ".." : "")
      );
    },
    asset1Label() {
      switch (this.mode) {
        case "transfer":
          return "จากบัญชี";
        case "expense":
          return "จ่ายด้วย";
        case "income":
          return "รับเข้า";
        default:
          return "error";
      }
    },
    asset1Accounts() {
      switch (this.mode) {
        case "transfer":
          return this.suggestedAssetsForTransferFrom.filter(
            acc =>
              !this.form.asset2_id || acc.account_id !== this.form.asset2_id
          );
        case "expense":
          return this.suggestedAssetsForWithdraw;
        case "income":
          return this.suggestedAssetsForDeposit;
        default:
          return [];
      }
    },
    asset2Accounts() {
      switch (this.mode) {
        case "transfer":
          return this.suggestedAssetsForTransferTo.filter(
            acc =>
              !this.form.asset1_id || acc.account_id !== this.form.asset1_id
          );
        case "expense":
        case "income":
        default:
          return [];
      }
    },
    suggestedCategoryAccounts() {
      if (this.mode === "expense") return this.suggestedCategoriesForWithdraw;
      else return this.suggestedCategoriesForDeposit;
    },
    displayAmountWithCommas() {
      return numberFormatComma(this.form.amount);
    },
    isNewTransaction() {
      return !this.transaction;
    },
    submitButtonLabel() {
      return this.isNewTransaction ? "Create" : "Save";
    },
    submitButtonIconOutline() {
      return this.isNewTransaction
        ? this.icons.addOutline
        : this.icons.saveOutline;
    },
    submitButtonIconSharp() {
      return this.isNewTransaction ? this.icons.addSharp : this.icons.saveSharp;
    },
    isSaveButtonDisabled() {
      if (this.submitting) return true;

      return false;
    },
  },
  methods: {
    ...mapActions({
      createTransaction: "save/createTransaction",
      updateTransaction: "save/updateTransaction",
    }),
    close() {
      modalController.dismiss();
    },
    shouldIFocusAmount() {
      if (this.form.asset1_id &&
        (
          (this.mode === "transfer" && this.form.asset2_id) ||
          (this.mode !== "transfer" && this.form.category_id)
        ) &&
        this.form.amount === ""
      ) {
        this.openCalculatorModal();
      }
    },
    setAsset1(account_id) {
      this.form.asset1_id = account_id;
      this.shouldIFocusAmount();
    },
    setAsset2(account_id) {
      this.form.asset2_id = account_id;
      this.shouldIFocusAmount();
    },
    setCategory(account_id) {
      this.form.category_id = account_id;
      this.shouldIFocusAmount();
    },
    async openCalculatorModal() {
      if (this.calculatorOpened) {
        return;
      }

      this.calculatorOpened = true;
      const componentProps = {
        value: this.form.amount,
      };
      if (this.form.asset1_id) {
        componentProps.memSlot1 = this.hashedAccounts[this.form.asset1_id].account_balance;
      }
      if (this.mode === "transfer") {
        if (this.form.asset2_id) {
          componentProps.memSlot2 = this.hashedAccounts[this.form.asset2_id].account_balance;
        }
      }
      const modal = await modalController.create({
        component: Calculator,
        componentProps,
        cssClass: "small-modal",
        showBackdrop: true,
        backdropDismiss: true,
        swipeToClose: true,
      });
      modal.onWillDismiss().then((receiving) => {
        if (typeof receiving.data === "string" && receiving.data.length > 0) {
          let decimal = receiving.data;
          if (decimal.indexOf(".") !== -1) {
            decimal = decimal.substr(0, decimal.indexOf(".") + 3);
          }
          this.form.amount = parseFloat(decimal);
        }
      });
      modal.onDidDismiss().then(() => {
        this.calculatorOpened = false;
      });
      return modal.present();
    },
    async Save() {
      this.submitting = true;

      const payload = {
        ...this.form,
        mode: this.mode,
        acc1: this.form.asset1_id,
      };
      if (this.mode === "transfer") {
        payload.acc2 = payload.asset2_id;
      } else {
        payload.acc2 = payload.category_id;
      }
      delete payload.asset1_id;
      delete payload.asset2_id;
      delete payload.category_id;

      if (!payload.acc2 || !payload.acc2 || !payload.amount) {
        const alert = await alertController.create({
          header: "กรอกข้อมูลไม่ครบ",
          buttons: [
            {
              text: "OK",
              role: "cancel",
              handler: () => {},
            },
          ],
        });
        await alert.present();
        this.submitting = false;
        return;
      }

      if (this.transaction) {
        payload.transaction_id = this.transaction.transaction_id;
        await this.updateTransaction(payload);
      } else {
        await this.createTransaction(payload);
      }

      await modalController.dismiss(true);
    },
    async onFocusAmount() {
      await this.openCalculatorModal();
    },
  },
};
</script>

<style scoped>
ion-segment-button.expense {
  --color: var(--ion-color-danger);
  --color-focused: var(--ion-color-danger);
  --color-checked: var(--ion-color-danger);
}
ion-segment-button.income {
  --color: var(--ion-color-success);
  --color-focused: var(--ion-color-success);
  --color-checked: var(--ion-color-success);
}
ion-item-divider.small {
  min-height: 0;
  padding-top: 2px;
  padding-bottom: 2px;
}
ion-item-divider.small ion-label {
  font-size: 65%;
}
</style>
