<template>
  <ion-list>
    <!-- กำลังโหลด -->
    <template v-if="loadingTransactions">
      <ion-progress-bar type="indeterminate"></ion-progress-bar>
    </template>
    <!-- แสดงผล -->
    <template v-else>
      <ion-item v-if="startBalance !== null && typeof startBalance !== 'undefined'">
        <ion-label color="medium" slot="end">
          <small>ยอดยกมา</small>
          {{ number_format(startBalance) }} บาท
        </ion-label>
      </ion-item>

      <template v-if="transactions.length === 0">
        <ion-item lines="none" class="ion-text-center">
          <template v-if="rangeFrom && rangeTo">
            <ion-label color="medium">
              <small> ไม่มีรายการในช่วง {{ rangeFrom }} ถึง {{ rangeTo }} </small>
            </ion-label>
          </template>
          <template v-else>
            <ion-label color="medium">
              <small> ไม่มีรายการ </small>
            </ion-label>
          </template>
        </ion-item>
      </template>

      <!-- Loop แสดงรายการ Transactions -->
      <ion-item-sliding
        v-for="t in transactions"
        :key="t.transaction_id"
        :ref="(el) => { slidingRefs[t.transaction_id] = el }"
      >

        <!-- เลื่อนเพื่อดูรายละเอียด -->
        <ion-item-options side="start">
          <ion-item-option color="secondary">
            {{ t.transaction_detail }}
          </ion-item-option>
        </ion-item-options>

        <!-- แสดงข้อมูลรายการ -->
        <ion-item lines="full">

          <!-- ชื่อบัญชี -->
          <ion-label>
            <p v-if="showDatetime">{{ t.transaction_date }} {{ t.transaction_time }}</p>
            <template v-if="t.transaction_format === 'transfer'">
              <div>
                <ion-text v-if="t.transaction_detail" color="secondary">
                  <b>|</b>
                </ion-text>
                {{ hashedAccounts[t.credit_id].account_name }} →
              </div>
              <div>{{ hashedAccounts[t.debit_id].account_name }}</div>
            </template>
            <template v-else-if="t.transaction_format === 'withdraw'">
              <ion-text v-if="t.transaction_detail" color="secondary">
                <b>|</b>
              </ion-text>
              {{ hashedAccounts[t.credit_id].account_name }}
              <p>
                {{ hashedCategories[t.debit_id].parentString }}
                {{ hashedCategories[t.debit_id].account_name }}
              </p>
            </template>
            <template v-else-if="t.transaction_format === 'deposit'">
              <ion-text v-if="t.transaction_detail" color="secondary">
                <b>|</b>
              </ion-text>
              {{ hashedAccounts[t.debit_id].account_name }}
              <p>
                {{ hashedCategories[t.credit_id].parentString }}
                {{ hashedCategories[t.credit_id].account_name }}
              </p>
            </template>
          </ion-label>

          <!-- ยอดเงิน -->
          <ion-label class="ion-text-right" slot="end">
            <template v-if="t.transaction_format === 'transfer'">
              <ion-text color="primary">฿ {{ number_format(t.amount) }}</ion-text>
            </template>
            <template v-else-if="t.transaction_format === 'withdraw'">
              <ion-text color="danger">฿ -{{ number_format(t.amount) }}</ion-text>
            </template>
            <template v-else-if="t.transaction_format === 'deposit'">
              <ion-text color="success">฿ {{ number_format(t.amount) }}</ion-text>
            </template>
            <!-- แสดง balance ถ้ามีส่งมา -->
            <p v-if="startBalance">{{ number_format(t.balance) }}</p>
          </ion-label>
        </ion-item>

        <!-- เลื่อน แก้ไข/ลบ -->
        <ion-item-options side="end">
          <ion-item-option color="danger" @click="confirmDelete(t)">
            <ion-icon
              :ios="deleteOutline"
              :md="deleteSharp"
            ></ion-icon>
          </ion-item-option>
          <ion-item-option color="primary" @click="edit(t)">
            <ion-icon
              :ios="editOutline"
              :md="editSharp"
            ></ion-icon>
            Edit
          </ion-item-option>
        </ion-item-options>

      </ion-item-sliding>

      <!-- แสดงยอดคงเหลือ -->
      <ion-item
        v-if="startBalance !== null && typeof startBalance !== 'undefined' && transactions.length > 0"
        lines="none"
      >
        <ion-label color="medium" slot="end">
          <small>คงเหลือ</small>
          {{ number_format(transactions[transactions.length-1].balance) }} บาท
        </ion-label>
      </ion-item>
    </template>
  </ion-list>
</template>

<script typeof="ts">
import { computed, ref } from "vue";
import {
  IonList,
  IonItem,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  IonText,
  IonLabel,
  IonIcon,
  alertController,
  modalController, IonProgressBar,
} from "@ionic/vue";
import { numberFormatComma } from "../../helper.js";
import { useStore } from "vuex";
import {
  pencilOutline,
  pencilSharp,
  trashOutline,
  trashSharp,
} from "ionicons/icons";
import ModalTransactionEditor from "./ModalTransactionEditor.vue";

export default {
  name: "TransactionList",
  components: {
    IonList,
    IonItem,
    IonItemSliding,
    IonItemOptions,
    IonItemOption,
    IonText,
    IonLabel,
    IonIcon,
    IonProgressBar,
  },
  props: {
    transactions: {
      type: Array,
      required: true,
    },
    startBalance: {
      type: Number,
    },
    showDatetime: {
      type: Boolean,
    },
    rangeFrom: {
      type: String,
    },
    rangeTo: {
      type: String,
    },
    loadingTransactions: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["change"],
  setup(props, { emit }) {
    const store = useStore();

    // eslint-disable-next-line prettier/prettier
    const hashedAccounts = computed(() => store.getters["account/hashedAccounts"]);
    // eslint-disable-next-line prettier/prettier
    const hashedCategories = computed(() => store.getters["account/hashedCategories"]);

    const slidingRefs = ref({});

    const confirmDelete = async (t) => {
      const alert = await alertController.create({
        header: "Confirm!",
        message: "Are you sure to delete this item?",
        buttons: [
          {
            text: "Cancel",
            role: "cancel",
          },
          {
            text: "Delete",
            handler: async () => {
              await store.dispatch("save/deleteTransaction", t.transaction_id);
              if (slidingRefs.value[t.transaction_id])
                slidingRefs.value[t.transaction_id].$el.close();
              emit("change"); // ask parent to update list
            },
          },
        ],
      });
      return alert.present();
    };

    const edit = async (t) => {
      const modal = await modalController.create({
        component: ModalTransactionEditor,
        componentProps: {
          transaction: t,
        },
        swipeToClose: false,
      });

      modal.onWillDismiss().then(() => {
        if (slidingRefs.value[t.transaction_id])
          slidingRefs.value[t.transaction_id].$el.close();
        emit("change"); // ask parent to update list
      });

      return modal.present();
    };

    return {
      slidingRefs,
      hashedAccounts,
      hashedCategories,
      // methods
      number_format: numberFormatComma,
      confirmDelete,
      edit,
      // icons
      deleteOutline: trashSharp,
      deleteSharp: trashOutline,
      editSharp: pencilSharp,
      editOutline: pencilOutline,
    };
  },
};
</script>

<style scoped></style>
