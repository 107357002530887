<template>
  <ion-item-group>
    <ion-item-divider class="small">
      <ion-label>{{ label }}</ion-label>
    </ion-item-divider>
    <template v-if="minimized">
      <!-- แสดง asset ที่เลือกไว้แล้วอันเดียว เพื่อประหยัดพื้นที่แสดงผล -->
      <ion-item lines="full">
        <template v-if="categoryMode">
          <ion-text color="medium">{{ hashedCategories[selectedAsset.account_id].parentString }}</ion-text>
        </template>
        <ion-label>{{ selectedAsset.account_name }}</ion-label>
        <template v-if="!categoryMode">
          <ion-text color="medium">
            <small>
              BAL {{ number_format(selectedAccountBalance) }}
            </small>
          </ion-text>
        </template>
        <ion-button slot="end" fill="outline" @click="change">Change</ion-button>
      </ion-item>

    </template>
    <template v-else>
      <!-- ให้เลือก asset -->
      <template v-for="acc in displayingSuggestedAccounts" :key="acc.account_id">
        <ion-item button detail="false" @click="setAccount(acc)">
          <template v-if="categoryMode">
            <ion-text color="medium">{{ hashedCategories[acc.account_id].parentString }}</ion-text>
          </template>
          <ion-label>{{ acc.account_name }}</ion-label>
          <!-- เลือกอะไรไว้ แสดงติ๊ก ให้รู้ด้วย -->
          <template v-if="account_id === acc.account_id">
            <ion-icon
              slot="end"
              :ios="icons.checkmarkOutline"
              :md="icons.checkmarkSharp"
            ></ion-icon>
          </template>
        </ion-item>
      </template>
      <ion-item button @click="openAccountPicker">More...</ion-item>
    </template>
  </ion-item-group>
</template>

<script>
import {
  // Utilities
  modalController,
  // Ionic Components
  IonButton,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonText
} from "@ionic/vue";
import { checkmarkOutline, checkmarkSharp } from "ionicons/icons";
import ModalAccountPicker from "./ModalAccountPicker.vue";
import { mapGetters } from "vuex";
import { numberFormatComma } from "../../helper.js";
export default {
  name: "AssetSelector",
  components: {
    IonButton,
    IonIcon,
    IonItem,
    IonItemDivider,
    IonItemGroup,
    IonLabel,
    IonText
  },
  props: {
    value: {
      required: true
    },
    label: {
      type: String,
      required: true
    },
    suggestedAccounts: {
      type: Array,
      required: true
    },
    allAccounts: {
      type: Array,
      required: true
    },
    categoryMode: {
      type: Boolean
    }
  },
  data() {
    return {
      account_id: this.value,
      minimized: !!this.value,
      openingAccountPicker: false,
    };
  },
  computed: {
    ...mapGetters({
      hashedCategories: "account/hashedCategories",
      hashedAccounts: "account/hashedAccounts",
    }),
    selectedAsset() {
      for (const acc of this.allAccounts) {
        if (acc.account_id === this.account_id) {
          return acc;
        }
      }
      return {};
    },
    selectedAccountBalance() {
      if (
        !this.selectedAsset ||
        !this.hashedAccounts[this.selectedAsset.account_id]
      ) {
        return null;
      }
      return this.hashedAccounts[this.selectedAsset.account_id].account_balance;
    },
    displayingSuggestedAccounts() {
      if (!this.account_id) {
        return this.suggestedAccounts;
      }

      let found = false;
      for (const acc of this.suggestedAccounts) {
        if (this.account_id === acc.account_id) {
          found = true;
          break;
        }
      }

      if (found) {
        return this.suggestedAccounts;
      } else {
        // ถ้าไม่เจออันที่เลือกไว้ก็ให้เติมเข้าหน้าสุด
        const making = [...this.suggestedAccounts].slice(0, -1);
        making.unshift(this.selectedAsset);
        return making;
      }
    },
    icons() {
      return {
        checkmarkOutline,
        checkmarkSharp
      };
    }
  },
  emits: ["input"],
  methods: {
    setAccount(account) {
      const account_id = typeof account === "number"
        ? account
        : account.account_id;
      this.account_id = account_id;
      this.minimized = true;
      this.$emit("input", account_id);
    },
    change() {
      this.minimized = false;
    },
    async openAccountPicker() {
      if (this.openingAccountPicker) return;
      this.openingAccountPicker = true;
      const modal = await modalController.create({
        component: ModalAccountPicker,
        componentProps: {
          accounts: this.allAccounts,
          value: this.account_id,
          categoryMode: this.categoryMode
        },
        swipeToClose: true
      });
      modal.onWillDismiss().then((receiving) => {
        this.openingAccountPicker = false;
        if (receiving.data && typeof receiving.data === "number") {
          this.setAccount(receiving.data);
        }
      });
      return modal.present();
    },
    number_format(num) {
      return numberFormatComma(num);
    },
  },
};
</script>

<style scoped>
ion-item-divider.small {
  min-height: 0;
  padding-top: 2px;
  padding-bottom: 2px;
}
ion-item-divider.small ion-label {
  font-size: 65%;
}
</style>
