<template>
  <ion-header>
    <ion-toolbar>
      <ion-title>เลือก</ion-title>
      <ion-buttons slot="end">
        <ion-button @click="dismiss">Close</ion-button>
      </ion-buttons>
    </ion-toolbar>
    <ion-toolbar>
      <ion-searchbar v-model="filter"></ion-searchbar>
    </ion-toolbar>
  </ion-header>

  <ion-content>
    <ion-list>
      <template v-for="account in displayingAccounts" :key="account.account_id">
        <ion-item button detail="false" @click="choose(account)">
          <template v-if="categoryMode">
            <ion-text color="medium">{{ hashedCategories[account.account_id].parentString }}</ion-text>
          </template>
          <ion-label>
            {{ account.account_name }}
          </ion-label>
          <template v-if="account.account_id === value">
            <ion-icon
              slot="end"
              :ios="icons.selectedOutline"
              :md="icons.selectedSharp"
            ></ion-icon>
          </template>
        </ion-item>
      </template>
    </ion-list>
  </ion-content>
</template>

<script>
import {
  // Utilities
  modalController,
  // Ionic Components
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonSearchbar,
  IonText,
  IonTitle,
  IonToolbar
} from "@ionic/vue";
import { mapGetters } from "vuex";
import { checkmarkOutline, checkmarkSharp } from "ionicons/icons";
export default {
  name: "ModalAccountPicker",
  components: {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonSearchbar,
    IonText,
    IonTitle,
    IonToolbar
  },
  data() {
    return {
      filter: ""
    };
  },
  props: {
    value: {
      required: true
    },
    accounts: {
      type: Array,
      required: true
    },
    categoryMode: {
      type: Boolean
    }
  },
  emits: ["dismiss", "input"],
  computed: {
    ...mapGetters({
      assets: "account/currentSaveAssets",
      categories: "account/currentSaveCategories",
      hashedCategories: "account/hashedCategories"
    }),
    displayingAccounts() {
      const filter = this.filter.toLowerCase();
      return this.accounts.filter(
        acc =>
          !this.filter ||
          acc.account_name.toLowerCase().indexOf(filter) !== -1 ||
          (this.categoryMode &&
            this.hashedCategories[acc.account_id].parentString.toLowerCase().indexOf(filter) !== -1)
      );
    },
    icons() {
      return {
        selectedOutline: checkmarkOutline,
        selectedSharp: checkmarkSharp
      };
    }
  },
  methods: {
    dismiss(passingValue) {
      modalController.dismiss(passingValue);
    },
    choose(account) {
      this.$emit("input", account.account_id);
      this.dismiss(account.account_id);
    }
  }
};
</script>

<style scoped></style>
